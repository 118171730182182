import {API_ENDPOINTS, Brand, LanguageEnum, parseUserInfo, parseUserSettings, User, UserInfoJSON, KcUser, KcAttribute, UserSettings, UserSettingsJSON} from 'shared-frontend';

import httpService from '@services/http.service';
import store, {Mutations} from '@/store';
import {logger} from '@services/logger.service';
import UserService from '@services/user.service';
import HttpService from '@services/http.service';

class SettingsService {
    private _userLocale: string;

    public get userLocale(): string {
        if (!this._userLocale) {
            this._userLocale = this.getUserLocale();
        }
        return this._userLocale;
    }

    public async init() {
        const userSettings: UserSettings = await this.getSettings();
        await this._updateUserSettingsWithCurrentBrandOrTimeZone(userSettings);
    }

    public async getSettings(): Promise<UserSettings> {
        const response = await httpService.get(API_ENDPOINTS.USER_SETTINGS());
        const userSettingsJSON: UserSettingsJSON = response.data;

        return parseUserSettings(userSettingsJSON);
    }

    public async updateSettings(userSettings: UserSettings): Promise<void> {
        await this._updateSettingsToServer(userSettings);
        this._setSettingsToLocalState(userSettings);
    }

    public getCurrentBrand(): Brand {
        return process.env.NEW_HOLLAND as Brand;
    }

    public getTimeZone(): string | null {
        return Intl.DateTimeFormat ? Intl.DateTimeFormat().resolvedOptions().timeZone : null;
    }

    public getUserLocale(): string {
        return navigator.language ?? 'nl-BE';
    }

    private async _updateUserSettingsWithCurrentBrandOrTimeZone(userSettings: UserSettings): Promise<void> {
        const {brand, language, reportTypes, timezone, warnProximity__m} = userSettings;

        // Retrieve current values for BRAND and TIMEZONE
        const currentBrand: Brand = this.getCurrentBrand();
        const currentTimezone: string = this.getTimeZone();

        logger.info('[settings.service] - BRAND: ', currentBrand);
        logger.info('[settings.service] - TIMEZONE (browser): ', currentTimezone);

        // If no changes, just set the initial userSettings
        if (brand === currentBrand && timezone === currentTimezone) {
            logger.info('[settings.service] - Brand or timezone hasn\'t changed');
            this._setSettingsToLocalState(userSettings);
            return;
        }

        logger.info('[settings.service] - Brand or timezone is different from server - updating server');
        if (brand !== currentBrand) logger.info('new BRAND: ', currentBrand);
        if (timezone !== currentTimezone) logger.info('new TIMEZONE: ', currentTimezone);

        const updatedSettings = new UserSettings(
            language,
            warnProximity__m,
            currentBrand || brand,
            currentTimezone || timezone,
            reportTypes
        );

        await this.updateSettings(updatedSettings);
    }

    private async _updateSettingsToServer(userSettings: UserSettings): Promise<void> {
        await httpService.put(API_ENDPOINTS.USER_SETTINGS(), userSettings);
    }

    private _setSettingsToLocalState(userSettings: UserSettings): void {
        this._setLanguageToLocalState(userSettings.language);
    }

    private _setLanguageToLocalState(language: LanguageEnum): void {
        store.commit(Mutations.UPDATE_LANGUAGE, language);
    }

    public async getConsent(): Promise<Boolean> {
        const response = await httpService.get(API_ENDPOINTS.CURRENT_USER_GET_CONSENT());
        return response.data["cnhi_consent"] === "true";
    }

    public async updateCnhiConsent(newConsent : boolean): Promise<KcUser> {
        const response = await httpService.patch(API_ENDPOINTS.CURRENT_USER_CHANGE_CONSENT(newConsent), {cnhi_consent: newConsent});
        return response.data;
    }
}

export default new SettingsService();
