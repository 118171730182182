import {ThirdParty, ThirdPartySummary, UsernameAndId} from '@/thirdPartyManagement/thirdParty.domain';

import httpService from '@services/http.service';
import moment from 'moment/moment';

export async function getThirdParties(): Promise<ThirdPartySummary[]> {
    const response = await httpService.get(`${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/apdt}/api/third-party/customers`);
    return response.data
        .map((item: any) => {
            return new ThirdPartySummary({...item});
        })
        .sort((a: ThirdPartySummary, b: ThirdPartySummary) => a.name > b.name);
}

export async function getThirdPartyDetails(id: string): Promise<ThirdParty | undefined> {
    const response = await httpService.get(`${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/apdt}/api/third-party/customer/${id}`);
    return response.data as ThirdParty
}

export async function updateThirdParty(thirdParty: ThirdParty) {
    const response = await httpService.put(`${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/apdt}/api/third-party/customer/${thirdParty.id}`, thirdParty);
    return response.data
}

export async function updateThirdPartyName(thirdParty: ThirdParty) {
    const response = await httpService.put(`${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/apdt}/api/third-party/customer/${thirdParty.id}/name`, thirdParty);
    return response.data
}

export async function updateThirdPartyKey(thirdParty: ThirdParty) {
    const response = await httpService.patch(`${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/apdt}/api/third-party/customer/${thirdParty.id}/key`, thirdParty.id);
    return response.data
}

export async function getThirdPartyDataProviders(): Promise<Array<string>> {
    const response = await httpService.get(`${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/apdt}/api/data-entity`);
    return response.data
}

export async function getConsentFarmers(): Promise<Array<UsernameAndId>> {
    const response = await httpService.get(`${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/apdt}/api/third-party/consent`);
    return response.data
}

export async function deleteThirdParty(thirdParty: ThirdParty) {
    const response = await httpService.delete(`${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/apdt}/api/third-party/customer/${thirdParty.id}`);
    return response.data
}

export async function createThirdParty(thirdParty: ThirdParty) {
    const response = await httpService.post(`${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/apdt}/api/third-party/customer`, {name: thirdParty.name});
    return response.data
}
moment.fn.toJSON = function() { return this.format("YYYY-MM-DD"); }
