<template>
  <v-app-bar :clipped-left="true" app color="toolbarColor" height="90">
    <img :src="brandImages.LOGO" alt="Logo" class="logo"/>
    <FarmTeamPicker v-if="showFarmTeamPicker"/>
    <v-spacer v-if="!showFarmTeamPicker"/>
    <v-toolbar-items>
      <ToolbarItem
          v-if="showSettings"
          :icon="icons.settings"
          @click="openSettings"
      >
        {{ translations.MENU.SETTINGS }}
      </ToolbarItem>
      <ToolbarItem :icon="icons.signOut" @click="logOut">{{ translations.MENU.LOG_OUT }}</ToolbarItem>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script lang="ts">
import Vue from 'vue';
import ToolbarItem from './components/ToolbarItem.vue';
import {mapState} from 'vuex';
import {icons} from '@utils/icons.utils';
import ModalService from '@/modals/modal.service';
import {ModalTypeEnum} from '@/modals/modalType.enum';
import KeycloakService from '@services/keycloak.service';
import {Brand, BrandingService} from 'shared-frontend';
import {images} from '@utils/assets.util';
import FarmTeamPicker from '@/farmTeam/farmTeamPicker/FarmTeamPicker.vue';
import {State} from '@/store';

export default Vue.extend({
  name: 'Header',
  components: {
    ToolbarItem,
    FarmTeamPicker
  },
  props: {
    showFarmTeamPicker: {
      type: Boolean,
      default: true
    },
    showSettings: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    icons(): { [name: string]: string } {
      return icons;
    },
    brandImages(): any {
      return BrandingService.getImages(process.env.NEW_HOLLAND as Brand, images);
    }
  },
  methods: {
    openSettings() {
      ModalService.showModal(ModalTypeEnum.SETTINGS);
    },
    logOut() {
      KeycloakService.getOrInit().then((instance) => instance.logout());
    }
  }
});
</script>

<style scoped></style>
