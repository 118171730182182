import httpService, {ContentTypeEnum} from "@services/http.service";
import FileSaver from 'file-saver';

class AdviceDetailService {
    public async getDownloadAdvice(adviceId: string, type = ContentTypeEnum.ZIP ): Promise<void> {
        return this.downloadFile(`${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/apdt}/api/advice/download/${adviceId}`, type);
    }

    private async downloadFile(apiEndpoint: string, type): Promise<void> {
        const response = await httpService.getFile(apiEndpoint, type, 'arraybuffer');

        const contentDisposition = response && response.headers && response.headers['content-disposition'];

        const fileName = contentDisposition ? this.getFileName(contentDisposition) : `advice`;

        return FileSaver.saveAs(new Blob([response.data], {type: type + ';charset=utf-8',}), fileName, {autoBom: false});
    }

    private getFileName(contentDispositionHeader: string): string {
        // content-disposition: "attachment; filename="binReports_2019_hello_bin_02.pdf""
        const fileName: string = contentDispositionHeader.split(' ')[1]; // output: filename="binReports_2019_hello_bin_02.pdf"

        return fileName.replace('filename="', '').replace('"', ''); // output: binReports_2019_hello_bin_02.pdf|csv
    }
}

export default new AdviceDetailService();
