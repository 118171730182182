import {API_ENDPOINTS, FieldEntityEnum, parseBalerTelemetry, parseCombineTelemetry, parseSpfhTelemetry, Team} from 'shared-frontend';
import {TelemetryConfig, TelemetrySseEvents} from '@/telemetry/telemetry.domain';

export const telemetrySSEConfig: { [fieldEntityType: string]: TelemetryConfig } = {
    [FieldEntityEnum.COMBINE]: {
        getUrl: (vehicleId: string) => ({ farmManagerId, id: teamId }: Team) =>
            `${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/rest}${API_ENDPOINTS.GET_TELEMETRY(farmManagerId, teamId, vehicleId)}`,
        eventTypes: [TelemetrySseEvents.COMBINE_TELEMETRY],
        parseTelemetry: (vehicleId: string) => (json: any) => parseCombineTelemetry(vehicleId, json)
    },

    [FieldEntityEnum.SPFH]: {
        getUrl: (vehicleId: string) => ({ farmManagerId, id: teamId }: Team) =>
            `${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/rest}${API_ENDPOINTS.SPFH_GET_TELEMETRY(farmManagerId, teamId, vehicleId)}`,
        eventTypes: [TelemetrySseEvents.SPFH_TELEMETRY],
        parseTelemetry: (vehicleId: string) => (json: any) => parseSpfhTelemetry(vehicleId, json)
    },

    [FieldEntityEnum.BALER]: {
        getUrl: (vehicleId: string) => ({ farmManagerId, id: teamId }: Team) =>
            `${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/rest}${API_ENDPOINTS.BALER_GET_TELEMETRY(farmManagerId, teamId, vehicleId)}`,
        eventTypes: [TelemetrySseEvents.BALER_TELEMETRY],
        parseTelemetry: (vehicleId: string) => (json: any) => parseBalerTelemetry(vehicleId, json)
    }
};
