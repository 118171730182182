<template>
  <div class="mapView polygonMap no-fill">
    <l-map
        ref="mapView"
        :max-zoom="20"
        :min-zoom="3"
        style="z-index: 1"
        @mousemove="fetchWmsLayerValues"
    >
      <l-tile-layer :options="{ maxZoom: 20, maxNativeZoom: 19, tileSize: 512, zoomOffset: -1  }"
                    :url="tileServerUrl" />
      <l-wms-layer
          v-if="showWMS && layer && layerStyle"
          :base-url="wmsServerUrl"
          :layers="layer"
          :styles="layerStyle.ref"
          :options="{ maxZoom: 20, maxNativeZoom: 19,  zoomOffset: -1  }"
          transparent
          format="image/png"
          layer-type="base"
          ref="wmsLayer"
      />
    </l-map>
  </div>
</template>

<style lang="scss">
.help-text {
  background-color: white;
  padding: 15px 30px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  align-items: center;

  & *:last-child {
    margin-bottom: 0;
  }

}
</style>

<script>
import { logger } from "@services/logger.service";
import { mapState } from "vuex";
import { API_ENDPOINTS, calculateBounds } from "shared-frontend";
import httpService from "@services/http.service";
import debounce from "@utils/debounce.util";
import { LatLng, LatLngLiteral, Popup } from "leaflet";
import { AdviceWmsStyle } from "@/apdtAdvice/advice.domain";

export default {
  name: "PolygonMap",
  components: {},
  props: {
    initialCoordinates: {
      type: Array,
      default: []
    },
    layer: String | undefined,
    layerStyle: AdviceWmsStyle | undefined,
    featureInfoEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      editableLayers: new window.L.FeatureGroup(),
      coordinates: [],
      intervalId: null,
      drawing: false,
      boundariesLayer: null,
      showWMS: true,
      wmsLegendControl: null
    };
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    }),
    tileServerUrl() {
      return process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/cache/tiles/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y};
    },
    wmsServerUrl() {
      return process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/geoserver/apdt/wms;
    },
    showZoomInHelpText() {
      return this.coordinates.length === 0;
    }
  },
  mounted() {
    require("./leaflet-wms-legend");

    this.coordinates = this.initialCoordinates;

    this.editableLayers.addTo(this.$refs.mapView.mapObject);

    // Use other translation for this because the original mentions a cancel, which we do not support
    L.drawLocal.edit.handlers.edit.tooltip.subtext = this.translations.GENERAL.EDIT;

    this.startListeningToMapMoves();

    this.$nextTick(() => {
      const thereIsAnInitialPolygon = this.coordinates.length === 0;
      if (thereIsAnInitialPolygon) {
        this.centerMapOnBrowserLocation();
      } else {
        this.centerMapOnPolygon();
      }
    });

    this.syncLeafletWithCoordinates();
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  created() {
    this.fetchWmsLayerValues = debounce(this.fetchWmsLayerValues, 100);
  },
  methods: {
    fetchWmsLayerValues: function(evt) {
      this.$refs.mapView.mapObject.closePopup();
      if (this.layer && this.layerStyle) {
        let mapObject = this.$refs.mapView.mapObject;
        // we have to round this, because after moving the map we get decimal values.
        let pointX = Math.ceil(mapObject.latLngToContainerPoint(evt.latlng, mapObject.getZoom()).x);
        let pointY = Math.ceil(mapObject.latLngToContainerPoint(evt.latlng, mapObject.getZoom()).y);
        let size = mapObject.getSize();
        let crs = mapObject.options.crs;
        let sw = crs.project(mapObject.getBounds().getSouthWest());
        let ne = crs.project(mapObject.getBounds().getNorthEast());
        let featureInfoQueryParams = {
          request: "GetFeatureInfo",
          service: "WMS",
          srs: crs.code,
          version: this.$refs.wmsLayer.version,
          bbox: sw.x + "," + sw.y + "," + ne.x + "," + ne.y,
          height: size.y,
          width: size.x,
          layers: this.layer,
          query_layers: this.layer,
          styles: this.layerStyle.ref,
          info_format: "application/json",
          x: pointX,
          y: pointY
        };
        if (this.featureInfoEnabled) {
          let featureInfoQueryParamsAsString = new URLSearchParams(featureInfoQueryParams).toString();
          let self = this;
          httpService.get(this.wmsServerUrl + "?" + featureInfoQueryParamsAsString).then(function(value) {
                if (value.data.features === undefined) {
                  return;
                }
                let NO_DATA_VALUE = -999;
                if (value.data.features.length > 0 && Object.keys(value.data.features[0].properties).length > 0) {
                  let featurePropertieKeys = Object.keys(value.data.features[0].properties);
                  var featureData;
                  let foundBandProperty = featurePropertieKeys.find(v => {
                    return v.includes("Band" + self.layerStyle.channel);
                  });
                  let foundValueProperty = featurePropertieKeys.find(v => {
                    return v.includes("value");
                  });
                  if (foundBandProperty !== undefined) {
                    featureData = value.data.features[0].properties[foundBandProperty];
                  } else if (foundValueProperty !== undefined) {
                    featureData = value.data.features[0].properties[foundValueProperty];
                  } else {
                    featureData = Object.values(value.data.features[0].properties)[0];
                  }
                  if (featureData !== NO_DATA_VALUE && featureData !== "" && featureData !== null) {
                    new L.popup()
                        .setLatLng(evt.latlng)
                        .setContent("<p>" + featureData + "</p>")
                        .openOn(mapObject);
                  }
                }
              }
          );
        }
      }
    },
    centerMapOnBrowserLocation() {
      if (!("geolocation" in navigator)) reject(new Error("Geolocation API not supported by browser!"));

      navigator.geolocation.getCurrentPosition(
          (position) => {
            const coords = position.coords;
            const { latitude, longitude } = coords || {};
            logger.info(
                `[BoundaryMap.component] - map centered on browserLocation - lat: ${latitude}, lon: ${longitude}`
            );
            this.setMapView(latitude, longitude, 18);
          },
          (error) => {
            logger.error("Geolocation API - getCurrentPosition - FAILED: ", error);
          }
      );
    },
    setMapView(latitude, longitude, zoom) {
      if (!latitude || !longitude) return;
      this.$refs.mapView.mapObject.setView([latitude, longitude], zoom);
    },
    centerMapOnPolygon() {
      const { maxLatitude, maxLongitude, minLatitude, minLongitude } = calculateBounds(this.coordinates);
      const bounds = [
        [minLatitude, minLongitude],
        [maxLatitude, maxLongitude]
      ];
      this.$refs.mapView.mapObject.fitBounds(bounds);
    },
    renderBoundaryDetail(fieldId) {
      this.fetchDetailsBoundary(fieldId).then(response => {
        this.boundariesLayer.clearLayers();
        this.editableLayers.clearLayers(); // A polygon might have been drawn before
        this.coordinates = response.data.geometry;
        const polygon = window.L.polygon(this.coordinates);
        polygon.addTo(this.editableLayers);
      });
    },
    fetchAndRenderBoundaries(map) {
      if (this.drawing || this.coordinates.length > 0) {
        return;
      }
      if (this.boundariesLayer != null) {
        map.removeLayer(this.boundariesLayer);
      }
      if (map.getZoom() >= 15) {
        this.fetchBboxBoundaries(map.getBounds().toBBoxString()).then(response => {
          if (response.data.length > 0) {
            this.boundariesLayer = window.L.featureGroup();
            response.data.forEach((geom) => {
              const polygon = window.L.polygon(geom.geometry);
              polygon.fieldId = geom.id;
              this.boundariesLayer.addLayer(polygon);
            });
            const self = this;
            this.boundariesLayer.on("click", function(event) {
              self.renderBoundaryDetail(event.layer.fieldId);
            });
            this.boundariesLayer.addTo(map);
          }
        });
      }
    }, startListeningToMapMoves() {
      const map = this.$refs.mapView.mapObject;
      map.on("moveend", (e) => {
        this.fetchAndRenderBoundaries(map);
      });
    },
    translate(coordinate) {
      return [coordinate.latitude, coordinate.longitude];
    },
    unTranslate(leafletCoordinate) {
      return { latitude: leafletCoordinate.lat, longitude: leafletCoordinate.lng };
    },
    syncLeafletWithCoordinates() {
      this.editableLayers.clearLayers(); // A polygon might have been drawn before
      const polygon = L.polygon(this.coordinates.map(coordinate => this.translate(coordinate)));
      polygon.addTo(this.editableLayers);
    },
    fetchBboxBoundaries(bbox) {
      return httpService.get(API_ENDPOINTS.BOUNDARY_CREATOR_LIST_CANDIDATES(bbox));
    },
    fetchDetailsBoundary(id) {
      return httpService.get(API_ENDPOINTS.BOUNDARY_CREATOR_CANDIDATE(id));
    },
    setLegend() {
      if (this.layer && this.layerStyle) {
        this.wmsLegendControl = window.L.wmsLegend(this.$refs.mapView.mapObject, `${this.wmsServerUrl}?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${this.layer}&STYLE=${this.layerStyle.ref}`);
      } else {
        this.$refs.mapView.mapObject.removeControl(this.wmsLegendControl);
      }
    }
  },
  watch: {
    layer() {
      this.showWMS = false;
      this.$nextTick(() => {
        this.showWMS = true;
        this.setLegend();
      });
      this.$refs.mapView.mapObject.closePopup();
    },
    layerStyle() {
      this.showWMS = false;
      this.$nextTick(() => {
        this.showWMS = true;
        this.setLegend();
      });
      this.$refs.mapView.mapObject.closePopup();
    }
  }
};
</script>
