import {FarmBoundaryId} from "shared-frontend";
import {
    Advice,
    AdviceFertilingN1InputData,
    AdviceFertilingN2InputData,
    AdviceFertilingN3InputData,
    AdviceFusariumInputData,
    AdviceGeoTiffWmsStyle,
    AdviceLimingInputData,
    AdviceShapeWmsStyle,
    AdviceSource,
    AdviceSowingInputData,
    AdviceWmsStyle,
    ApdtField,
    UseableSource
} from "@/apdtAdvice/advice.domain";
import moment, {Duration} from "moment";
import {AdviceForm} from "@/apdtAdvice/forms/form.builder";
import httpService from "@services/http.service";
import {ActivityType, AdviceSourceType} from "@/apdtAdvice/advice.enum";
import {AxiosResponse} from 'axios';

const APDT_BASE_URL = `${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/apdt}`;

export async function getAdviceHistoryForBoundary(farmBoundaryId: FarmBoundaryId): Promise<Advice[]> {
    return await httpService.get(`${APDT_BASE_URL}/api/advice?field=${farmBoundaryId}`).then(value =>
        value.data.map((item) => {
            let thumbnailSelectedStyleNbr = getStyleForDefaultZoneOrHighest(item, 3)
            item.geoserverStyles = item.geoserverStyles.sort((a: AdviceWmsStyle, b: AdviceWmsStyle) => a.sortOrder - b.sortOrder);
            return new Advice({
                action: item.inputData.activityType,
                creationDate: new Date(item.requestedOn),
                id: item.id,
                motivation: item.motivation,
                geoserverLayerId: item.geoserverLayerId,
                geoserverStylesId: item.geoserverStylesId,
                inputData: createInputData(item.inputData),
                thumbnailUrl: `${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/geoserver/apdt/wms}/reflect?layers=${item.geoserverLayerId}&styles=${item.geoserverStyles[thumbnailSelectedStyleNbr].styleRef}&format=image/png&width=100&height=100&transparent=true`,
                sources: item.selectedSources
            });
        })
            .sort(advice => advice.creationDate));
}

export function mapToWmsStyle() {
    return (style) => {
        if (style.type === "SHAPE") {
            return new AdviceShapeWmsStyle({name: style.name, ref: style.styleRef, sortOrder: style.sortOrder});
        } else {
            return new AdviceGeoTiffWmsStyle({name: style.name, ref: style.styleRef, channel: style.channel, sortOrder: style.sortOrder});
        }
    };
}

function createInputData(inputData) {
    switch (inputData.activityType) {
        case ActivityType.LIMING:
            return new AdviceLimingInputData(inputData)
        case ActivityType.SOWING:
            return new AdviceSowingInputData(inputData)
        case ActivityType.FUSARIUM:
            return new AdviceFusariumInputData(inputData)
        case ActivityType.FERTILIZING_N1:
            return new AdviceFertilingN1InputData(inputData)
        case ActivityType.FERTILIZING_N2:
            return new AdviceFertilingN2InputData(inputData)
        case ActivityType.FERTILIZING_N3:
            return new AdviceFertilingN3InputData(inputData)
        case ActivityType.APHID:
            return inputData
        case ActivityType.OULEMA:
            return inputData
    }
}

export async function getAdvice(adviceId: string): Promise<Advice> {
    return httpService.get(`${APDT_BASE_URL}/api/advice/${adviceId}`).then(value => new Advice({
        action: value.data.inputData.activityType,
        creationDate: new Date(value.data.requestedOn),
        id: value.data.id,
        motivation: value.data.motivation,
        geoserverLayerId: value.data.geoserverLayerId,
        geoserverStyles: value.data.geoserverStyles.map(
            mapToWmsStyle()
        ),
        inputData: createInputData(value.data.inputData),
        sources: value.data.selectedSources
    }));
}

export function getStyleForDefaultZoneOrHighest(item: Advice, defaultZone: number) {
    const nbrSelectableStyles = item.geoserverStyles.length
    if (nbrSelectableStyles >= defaultZone) {
        return defaultZone - 1;
    } else {
        return nbrSelectableStyles - 1;
    }
}

function getDay(day: string): moment.Moment {
    return moment.utc(day);
}


export async function findApdtField(farmBoundaryId: FarmBoundaryId): Promise<ApdtField> {
    const baseUrl = `${APDT_BASE_URL}/api/farmer`;
    const responseAPDTField = await httpService.get(`${baseUrl}/field?hca-ref=${farmBoundaryId}`);
    if (responseAPDTField.data[0]) {
        return new ApdtField(responseAPDTField.data[0]);
    } else {
        return null;
    }
}

export async function getAdviceSources(apdtField: ApdtField): Promise<AdviceSource[]> {
    const baseUrl = `${APDT_BASE_URL}/api/farmer`;

    const responseFieldLayers = await httpService.get(
        `${baseUrl}/fields/${apdtField.fieldId}/layers`
    );

    return mapFieldLayersToAdviceSources(responseFieldLayers);
}

function mapFieldLayersToAdviceSources(responseFieldLayers: AxiosResponse<any>) {
    return responseFieldLayers.data.map(
        (datasource) => {
            if (AdviceSourceType[datasource.serviceType.toUpperCase()]) {
                let adviceSourceTypeElement = AdviceSourceType[datasource.serviceType.toUpperCase()];
                return new AdviceSource({
                    type: adviceSourceTypeElement,
                    options: datasource.dataSourceTypes.map((value) => ({
                        name: value.type,
                        active: false,
                        thumbnailUrl:
                            getThumbnailUrl(value.layers),
                        data: value.layers.map((value) => ({
                            day: getDay(value.date),
                            layerName: value.type,
                            wmsLayer: value.layer.layerRef,
                            wmsStyles: value.layer.styles.map(
                                mapToWmsStyle())
                        }))
                    }))
                });
            } else {
                return new AdviceSource({
                    type: datasource.serviceType.toUpperCase(),
                    options: datasource.dataSourceTypes.map((value) => ({
                        name: value.type,
                        active: false,
                        thumbnailUrl: null,
                        data: value.layers.map((value) => ({
                            day: getDay(value.date),
                            weather: value.layer.map.data.map((data) => ({
                                temp: data.temp,
                                hum: data.hum,
                                rain: data.rain,
                                weather_code: data.weather_code,
                                wind_speed: data.wind_speed
                            }))
                        }))
                    }))
                });
            }
        }
    );
}

export async function getAdviceSourcesForType(apdtField: ApdtField, adviceSourceType: AdviceSourceType): Promise<AdviceSource[]> {
    const baseUrl = `${APDT_BASE_URL}/api/farmer`;

    const responseFieldLayers = await httpService.get(
        `${baseUrl}/fields/${apdtField.fieldId}/${adviceSourceType.toLowerCase()}/layers`
    );

    return mapFieldLayersToAdviceSources(responseFieldLayers);
}

export async function getMergePeriodThreshold(): Promise<Duration> {
    const baseUrl = `${APDT_BASE_URL}/api/farmer`;

    const response = await httpService.get(
        `${APDT_BASE_URL}/api/cn1-merge-period-threshold`
    );
    return moment.duration(response.data)
}


export async function mergeLayers(apdtField: ApdtField, selectedDates: String[],): Promise<Duration> {
    let body = {
        dates: selectedDates
    };
    return await httpService.post(
        `${APDT_BASE_URL}/api/field/${apdtField.fieldId}/cn1-merge`, body
    ).then(value => value.data);
}


function getThumbnailUrl(layers) {
    try {
        let mostRecentLayer = layers.sort((a, b) => getDay(b.date).day().valueOf() - getDay(a.date).day().valueOf())[0];
        let thumbnailStyle =
            mostRecentLayer.layer.styles.find((value) => value.name == "ZONE_THREE") || mostRecentLayer.layer.styles[0];
        return createThumbnailUrl(layers[0].layer.layerRef, thumbnailStyle.styleRef);
    } catch (e) {
        return undefined;
    }
}

export function createThumbnailUrl(layerRef: string, styleRef: string) {
    try {
        return `${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/geoserver/apdt/wms}/reflect?layers=${layerRef}&styles=${styleRef}&format=image/png&width=100&height=100&transparent=true`;
    } catch (e) {
        return undefined;
    }
}

export async function getSoilTypes(): Promise<{
    value: string
}[]> {
    return await httpService.get(
        `${APDT_BASE_URL}/api/advice/soil-types`
    ).then(value => value.data);
}

export async function getActivityTypes(): Promise<{
    value: string
}[]> {
    return await httpService.get(
        `${APDT_BASE_URL}/api/advice/activity-types`
    ).then(value => value.data);
}

export async function generateAdvice(farmBoundaryId: String, adviceParams: AdviceForm): Promise<String> {
    let body = {
        fieldId: farmBoundaryId,
        ...adviceParams.toJSON()
    };
    return await httpService.post(
        `${APDT_BASE_URL}/api/advice/create`, body
    ).then(value => value.data);
}

export async function getUseableSources(actionType: String): Promise<UseableSource[]> {
    return await httpService.get(`${APDT_BASE_URL}/api/advice/${actionType}/sensors`)
        .then(value => value.data);
}
