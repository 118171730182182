<template>
  <v-navigation-drawer class="navigation-drawer" app clipped color="black" permanent width="75">
    <div>
      <!-- v-if on template here because we need div for css -->
      <template v-if="showNavItems">
        <a
            v-for="{ routeName, icon } of navItems"
            :active="isActive(routeName)"
            :class="{ 'sideBar__item--active': isActive(routeName) }"
            class="sideBar__item"
            @click="goTo(routeName, 'map')"
        >
          <v-icon :color="isActive(routeName) ? 'secondary' : 'white'" large>{{ icon }}</v-icon>
        </a></template>

      <template v-if="showAdminItems">
        <a v-for="{ routeName, icon } of adminItems"
           :active="isActive(routeName)"
           :class="{ 'sideBar__item&#45;&#45;active': isActive(routeName) }"
           class="sideBar__item"
           @click="goTo(routeName, 'admin')"
        >
          <v-icon :color="isActive(routeName) ? 'secondary' : 'white'" large>{{ icon }}</v-icon>
        </a>
      </template>
    </div>

    <div>
      <router-link
          v-if="isAdmin && showToAdmin"
          :to="{name: 'admin'}" class="sideBar__item sideBar__item--toMyFarm"
          :style="{ backgroundColor: toAdminBgColor }"
      >
        <v-icon color="white" large class="mb-2">{{ icons.backTriangle }}</v-icon>
        {{ translations.MENU.TO_ADMIN_PAGE }}
      </router-link>

      <router-link
          v-if="showToPortal"
          :to="{name: 'index'}" class="sideBar__item sideBar__item--toMyFarm"
          :style="{ backgroundColor: toAdminBgColor }"
      >
        <v-icon color="white" large class="mb-2">{{ icons.backTriangle }}</v-icon>
        {{ translations.MENU.TO_PORTAL }}
      </router-link>

      <a :href="myFarmUrl" class="sideBar__item sideBar__item--toMyFarm" target="_blank" :style="{ backgroundColor: myFarmBtnBgColor }">
        <v-icon color="white" large class="mb-2">{{ icons.backTriangle }}</v-icon>
        {{ translations.MENU.BACK_TO_MY_FARM }}
      </a>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from "vue";
import {icons} from "@utils/icons.utils";
import {Brand, BrandingService, hexToRgba} from "shared-frontend";
import {mapState} from "vuex";
import {State} from "@/store";
import authenticationService from "@services/authentication.service";

export default Vue.extend({
  name: 'Navigation',
  props: {
    showNavItems: {
      type: Boolean,
      default: true
    },
    showAdminItems: {
      type: Boolean,
      default: false
    },
    showToAdmin: {
      type: Boolean,
      default: false
    },
    showToPortal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isAdmin: false
    }
  },
  async created() {
    this.isAdmin = await authenticationService.isAdmin();
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    icons() {
      return icons;
    },
    navItems() {
      const items = [
        {routeName: 'teamInfo', icon: icons.infoCircle},
        {routeName: 'messaging', icon: icons.message},
        {routeName: 'reports', icon: icons.report, subRoutes: ['fieldReport']},
        {routeName: 'farmBoundaries', icon: icons.boundary, subRoutes: ['addFarmBoundary', 'editFarmBoundary']},
        {routeName: 'workforce', icon: icons.users}
      ]
      console.log(process.env.false)
      if (process.env.false==="true") {
        items.push({routeName: 'agdnaImport', icon: icons.fileImport})
      }

      return items;
    },
    adminItems() {
      return [
        {routeName: 'admin', icon: icons.bin},
        {routeName: 'thirdPartyManagement', icon: icons.handshake, subRoutes: ['thirdPartyManagementDetails']}
      ];
    },
    myFarmBtnBgColor(): string {
      return hexToRgba(BrandingService.getColors(process.env.NEW_HOLLAND as Brand ?? Brand.NEW_HOLLAND)?.SECONDARY, .4)
    },
    toAdminBgColor(): string {
      return hexToRgba(BrandingService.getColors(process.env.NEW_HOLLAND as Brand ?? Brand.NEW_HOLLAND)?.LIGHT_GREY, .4)
    },
    myFarmUrl() {
      return process.env.NEW_HOLLAND === Brand.NEW_HOLLAND ? process.env.https://farm-newholland.agdna.com : process.env.https://farm-caseih.agdna.com;
    },
  },
  methods: {
    isActive(routeName: string): boolean {
      const {subRoutes} = this.navItems.find((navItem: any) => navItem.routeName === routeName) ?? {};
      return this.$route.matched.some(({name}) => name === routeName || subRoutes?.includes(name));
    },

    goTo(routeName: string, defo: string): void {
      if (this.isActive(routeName)) {
        this.$router.push({name: defo});
      } else {
        this.$router.push({name: routeName});
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.navigation-drawer {
  transform: translateX(0) !important;
  visibility: visible !important;
}
</style>
