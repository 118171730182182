<template>
  <StrawBale v-if="Boolean(bale)" :bale="bale" :style="styling"/>
  <component v-else :is="svgIcon" :class="iconClass" :style="styling"/>
</template>

import Vue from 'vue';

<script lang="ts">
import Vue, {PropType} from 'vue';
import {
  Baler,
  BalerLoadReport,
  BaleServiceVehicle,
  BaleServiceVehicleTypeEnum,
  Bin,
  BinIcon,
  BinTypeEnum,
  Brand,
  BrandingService,
  Combine,
  Dump,
  FieldEntity,
  Gate,
  HeaderType,
  IconColors,
  Marker,
  MarkerType,
  MetalDetection,
  Person,
  Spfh,
  SpfhIcon,
  SpfhTelemetrySummary,
  User,
  Vehicle
} from 'shared-frontend';
import {getRotationStyling, getSizeStyling} from '@/map/icons/MapIcon.helper';

import CombineIconSVG from '@assets/icons/combine/combine.svg';
import NormalBinIconSVG from '@assets/icons/normalbin/normalbin.svg';
import NormalBinStationaryIconSVG from '@assets/icons/normalbin/normalbin_stationary.svg';
import ChaserBinIconSVG from '@assets/icons/chaserbin/chaserbin.svg';
import MotherBinIconSVG from '@assets/icons/motherbin/motherbin.svg';
import TruckIconSVG from '@assets/icons/truck/truck.svg';
import BalerIconSVG from '@assets/icons/baler/baler.svg';
import BalerStationaryIconSVG from '@assets/icons/baler/baler_stationary.svg';
import PersonIconSVG from '@assets/icons/person/person.svg';
import SpfhCombineCornHeaderIconSVG from '@assets/icons/spfh/spfh_combinecornheader.svg';
import SpfhForageCornHeaderIconSVG from '@assets/icons/spfh/spfh_foragecornheader.svg';
import SpfhGrainHeaderIconSVG from '@assets/icons/spfh/spfh_grainheader.svg';
import SpfhPickupHeaderIconSVG from '@assets/icons/spfh/spfh_pickupheader.svg';
import GateIconSVG from '@assets/icons/gate/gate_with_txt.svg';
import DumpIconSVG from '@assets/icons/dump/silo.svg';
import PictureMarkerIconSVG from '@assets/icons/marker/picture_marker.svg';
import ObstacleMarkerIconSVG from '@assets/icons/marker/obstacle_marker.svg';
import StrawBale from './StrawBale.icon.vue';
import MetalDetectionSVG from '@assets/icons/metaldetection/metal_detection.svg'
import {BaleServiceVehicleIcon} from 'shared-frontend/dist/icon/icon.domain';
import BaleWrapperIconSVG from '@assets/icons/balewrapper/bale_wrapper.svg';
import BalePickupIconSVG from '@assets/icons/balepickup/bale_pickup.svg';

export default Vue.extend({
  name: 'MapIcon',
  components: {
    CombineIconSVG,
    NormalBinIconSVG,
    NormalBinStationaryIconSVG,
    ChaserBinIconSVG,
    MotherBinIconSVG,
    TruckIconSVG,
    BalerIconSVG,
    BalerStationaryIconSVG,
    PersonIconSVG,
    SpfhCombineCornHeaderIconSVG,
    SpfhForageCornHeaderIconSVG,
    SpfhGrainHeaderIconSVG,
    SpfhPickupHeaderIconSVG,
    GateIconSVG,
    DumpIconSVG,
    StrawBale,
    PictureMarkerIconSVG,
    ObstacleMarkerIconSVG,
    MetalDetectionSVG,
    BaleWrapperIconSVG,
    BalePickupIconSVG
  },
  props: {
    fieldEntity: Object as PropType<FieldEntity>,
    zoom: {
      type: Number,
      required: false // zoom is only taken into account if no 'customWidth' is set
    },
    customWidth: {
      type: Number,
      required: false // takes precedence over 'zoom'
    },
    noColor: {
      type: Boolean,
      required: false,
      default: false
    },
    rotation: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    svgIcon(): string {
      switch (this.fieldEntity.constructor) {
        case Combine:
          return 'CombineIconSVG';
        case Bin:
          return (this as any).binSvgIcon(this.fieldEntity as Bin);
        case BaleServiceVehicle:
          return (this as any).baleServiceVehicleSvgIcon(this.fieldEntity as BaleServiceVehicle);
        case Baler:
          return (this as any).balerSvgIcon(this.fieldEntity as Baler);
        case Spfh:
          return (this as any).spfhSvgIcon(this.fieldEntity as Spfh);
        case User:
          return 'PersonIconSVG';
        case Dump:
          return 'DumpIconSVG';
        case Gate:
          return 'GateIconSVG';
        case BalerLoadReport:
          return 'StrawBale';
        case Marker:
          return (this as any).markerSvgIcon(this.fieldEntity as Marker);
        case MetalDetection:
          return 'MetalDetectionSVG';
        default:
          return null;
      }
    },
    bale(): BalerLoadReport {
      return this.fieldEntity instanceof BalerLoadReport ? this.fieldEntity : undefined;
    },
    styling() {
      return {
        ...getSizeStyling(this.fieldEntity, this.zoom, this.customWidth),
        ...(this.rotation ? getRotationStyling(this.fieldEntity) : {}),
        ...(this.noColor ? {filter: 'grayscale(100%)'} : {})
      };
    },
    colorClass(): { [color: string]: boolean } {
      const color = (this.fieldEntity as Vehicle)?.icon?.color ?? ((this.fieldEntity instanceof Person)? IconColors.RED: undefined);
      if (color) {
        return {[color]: true};
      } else {
        return {}
      }
    },
    unloadingClass(): { unloading: boolean } {
      const unloading = (this.fieldEntity as Spfh | Combine)?.telemetry?.isUnloading ?? false;
      return {unloading}
    },
    unloadingDirectionClass(): { [className: string]: boolean } {
      if (this.fieldEntity instanceof Spfh && this.fieldEntity.telemetry?.isUnloading) {
        const direction = (this.fieldEntity.telemetry as SpfhTelemetrySummary).unloadingDirection;
        return {[`unloadingDirection-${direction}`]: true}
      } else {
        return {};
      }
    },
    iconClass(): { [className: string]: boolean } {
      return {
        mapIcon: true,
        ...(this as any).colorClass,
        ...(this as any).unloadingClass,
        ...(this as any).unloadingDirectionClass,
      };
    },
    brandColors() {
      return BrandingService.getColors(process.env.NEW_HOLLAND as Brand);
    }
  },
  methods: {
    binSvgIcon: function (bin: Bin): string {
      const binIcon = bin.icon as BinIcon;
      const isStationary = !bin.assignedTo;

      switch (binIcon.binType) {
        case BinTypeEnum.NORMAL_BIN:
          return isStationary ? 'NormalBinStationaryIconSVG' : 'NormalBinIconSVG';
        case BinTypeEnum.CHASER_BIN:
          return 'ChaserBinIconSVG';
        case BinTypeEnum.MOTHER_BIN:
          return 'MotherBinIconSVG';
        case BinTypeEnum.TRUCK:
          return 'TruckIconSVG';
      }
    }
    ,
    baleServiceVehicleSvgIcon: function (baleServiceVehicle: BaleServiceVehicle): string {
      const baleServiceVehicleIcon = baleServiceVehicle.icon as BaleServiceVehicleIcon;

      switch (baleServiceVehicleIcon.baleServiceVehicleType) {
        case BaleServiceVehicleTypeEnum.WRAPPER:
          return 'BaleWrapperIconSVG';
        case BaleServiceVehicleTypeEnum.PICKUP:
          return 'BalePickupIconSVG';
      }
      throw Error(`Could not find icon for ${baleServiceVehicleIcon.baleServiceVehicleType}`)
    }
    ,
    balerSvgIcon: function (baler: Baler): string {
      const isStationary = !baler.assignedTo;
      return isStationary ? 'BalerStationaryIconSVG' : 'BalerIconSVG';
    }
    ,
    spfhSvgIcon: function (spfh: Spfh): string {
      const headerType = (spfh.icon as SpfhIcon).headerType;

      switch (headerType) {
        case HeaderType.SPFH_COPPICE:
          return 'SpfhForageCornHeaderIconSVG';
        case HeaderType.SPFH_RI_CORNHEADER:
          return 'SpfhCombineCornHeaderIconSVG';
        case HeaderType.SPFH_DIRECTCUT:
          return 'SpfhGrainHeaderIconSVG';
        case HeaderType.SPFH_PICKUP:
          return 'SpfhPickupHeaderIconSVG';
        default:
          return 'SpfhCombineCornHeaderIconSVG';

      }
    }
    ,
    markerSvgIcon(marker: Marker): string {
      switch (marker.type) {
        case MarkerType.PICTURE:
          return 'PictureMarkerIconSVG'
        case MarkerType.OBSTACLE:
          return 'ObstacleMarkerIconSVG'
        default:
          throw Error(`Could not find icon for marker with type ${marker.type}`)
      }
    }
  }
})
;
</script>
