<template>
  <v-form @submit.prevent="startExport">
    <template v-if="step === Step.CONFIRM">
      <p>{{ trans.MODALS.CONFIRM_UPLOAD_TO_EVO_PORTAL.CONFIRM(target) }}</p>
      <div class="form__actions">
        <v-btn @click="$emit('close')" color="error" text>{{ trans.GENERAL.CANCEL }}</v-btn>
        <v-btn type="submit" color="primary" dark
        >{{ trans.GENERAL.CONFIRM }}
        </v-btn>
      </div>
    </template>

    <template v-if="step === Step.UPLOADING">
      <p>{{ trans.MODALS.CONFIRM_UPLOAD_TO_EVO_PORTAL.EXPORTING(target) }}</p>
      <div class="form__actions">
        <v-btn type="submit" color="primary" :loading="true" dark />
      </div>
    </template>

    <template v-else-if="step === Step.FAILURE">
      <p>{{ trans.MODALS.CONFIRM_UPLOAD_TO_EVO_PORTAL.FAILURE(target) }}</p>
      <div class="form__actions">
        <v-btn @click="$emit('close')" color="error" text>{{ trans.GENERAL.CANCEL }}</v-btn>
        <v-btn type="submit" color="primary" :click="startExport" dark
        >{{ trans.GENERAL.RETRY }}
        </v-btn>
      </div>
    </template>

    <template v-else-if="step === Step.SUCCESS">
      <p>{{ trans.MODALS.CONFIRM_UPLOAD_TO_EVO_PORTAL.SUCCESS(target) }}</p>
      <p class="confirmUploadToEvoPortal__uploadedFileName">{{ uploadedFileName }}</p>
      <div class="form__actions">
        <v-btn @click="$emit('close')" color="error" text>
          {{ trans.GENERAL.CLOSE }}
        </v-btn>
        <v-btn color="primary" dark @click="showHowToProcess">
          {{ trans.MODALS.CONFIRM_UPLOAD_TO_EVO_PORTAL.SHOW_HOW_TO_PROCESS }}
        </v-btn>
      </div>
    </template>

    <template v-else-if="step === Step.HOW_TO_PROCESS">
      <ol>
        <li>{{ trans.MODALS.CONFIRM_UPLOAD_TO_EVO_PORTAL.HOW_TO_PROCESS.STEP_1 }}</li>
        <li>
          <p>{{ trans.MODALS.CONFIRM_UPLOAD_TO_EVO_PORTAL.HOW_TO_PROCESS.STEP_2 }}</p>
          <img :src="brandImages.EVO_PORTAL_GEAR" />
        </li>
        <li v-html="trans.MODALS.CONFIRM_UPLOAD_TO_EVO_PORTAL.HOW_TO_PROCESS.STEP_3" />
        <li v-html="trans.MODALS.CONFIRM_UPLOAD_TO_EVO_PORTAL.HOW_TO_PROCESS.STEP_4(uploadedFileName)" />
        <li v-html="trans.MODALS.CONFIRM_UPLOAD_TO_EVO_PORTAL.HOW_TO_PROCESS.STEP_5" />
        <li>{{ trans.MODALS.CONFIRM_UPLOAD_TO_EVO_PORTAL.HOW_TO_PROCESS.STEP_6 }}</li>
      </ol>
      <div class="form__actions">
        <v-btn @click="$emit('close')" color="error" text>
          {{ trans.GENERAL.CLOSE }}
        </v-btn>
      </div>
    </template>

  </v-form>
</template>

<script lang="ts">
import {mapState} from "vuex";
import FarmBoundaryService from "@services/farmBoundary.service";
import FarmTeamService from "../farmTeam/farmTeam.service";
import {Brand, BrandingService, FarmBoundaryId} from 'shared-frontend'
import {State} from '@/store'
import {images} from '@utils/assets.util'
import {FarmTeamId} from "@/farmTeam/farmTeam.domain";

enum Step {
  CONFIRM,
  UPLOADING,
  FAILURE,
  SUCCESS,
  HOW_TO_PROCESS
}

export default {
  components: {},
  props: {
    params: Object
  },
  data() {
    return {
      step: Step.CONFIRM,
      Step,
      uploadedFileName: null
    }
  },
  computed: {
    ...mapState({
      trans: (state: State) => state.translation.translations
    }),
    farmBoundaryId(): FarmBoundaryId {
      return (this as any).params.farmBoundaryId;
    },
    farmTeamId(): FarmTeamId {
      return (this as any).params.farmTeamId;
    },
    target(): string {
      return this.farmBoundaryId? "boundary" : "team"
    },
    brandImages(): any {
      return BrandingService.getImages(process.env.NEW_HOLLAND as Brand, images);
    }
  },
  methods: {
    async startExport() {
      try {
        (this as any).step = Step.UPLOADING;

        if((this as any).target === 'boundary') {
          (this as any).uploadedFileName = await FarmBoundaryService.uploadToEvoPortal((this as any).farmBoundaryId)
        } else {
          (this as any).uploadedFileName = await FarmTeamService.uploadToEvoPortal((this as any).farmTeamId);
        }

        (this as any).step = Step.SUCCESS;
      } catch (e) {
        (this as any).step = Step.FAILURE;
      }
    },
    showHowToProcess() {
      (this as any).step = Step.HOW_TO_PROCESS
    }
  },

}
</script>
