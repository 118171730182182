<template>
  <fragment>
    <v-card class="dashboard__card dashboard__card--half ">
      <v-card-title :style="{ backgroundColor: brandColors.SECONDARY }">
        <span>{{ translations.DASHBOARD.WORKFORCE_ARABLE_VEHICLES }}</span>
        <InfoIcon :text="translations.DASHBOARD.WORKFORCE_ARABLE_VEHICLES_INFO"/>
      </v-card-title>
      <v-card-text class="dashboard__textContainer pt-2">
        <ul v-if="arableVehicles && arableVehicles.length" class="dashboard__list">
          <li v-for="arableVehicle in arableVehicles" class="dashboard__item dashboard__item--arableVehicles">
            <MapIcon :custom-width="80" :field-entity="getAsAssigned(arableVehicle)"/>
            <span class="dashboard__text mr-3"
            ><span class="dashboard__text-title">{{ arableVehicle.ssid }}</span>
                </span>
          </li>
        </ul>
        <template v-else-if="!arableVehicles">
          <v-progress-circular color="primary" indeterminate/>
        </template>
        <template v-else>
          <p style="font-weight: bold">
            {{ translations.DASHBOARD.NO_FARM_ARABLE_VEHICLES.PARAGRAPH_1 }}
          </p>
          <p>{{ translations.DASHBOARD.NO_FARM_ARABLE_VEHICLES.PARAGRAPH_2 }}</p>
        </template>
      </v-card-text>
    </v-card>
  </fragment>
</template>

<script lang="ts">
import Vue from 'vue';
import {mapState} from 'vuex';
import InfoIcon from '@components/InfoIcon.component.vue';
import MapIcon from '@/map/icons/MapIcon.icon.vue';
import {Icons, icons} from '@utils/icons.utils';
import FarmCombinesService from '@services/farmCombines.service';
import FarmSpfhsService from '@services/farmSpfhs.service';
import FarmBalersService from '@services/farmBalers.service';
import {Balers, Bin, Brand, BrandingService, Combine, Combines, Spfhs} from 'shared-frontend';
import {State} from '@/store';

export default Vue.extend({
  name: 'ArableVehicles',
  data() {
    return {
      arableVehicles: null
    };
  },
  components: {
    InfoIcon,
    MapIcon
  },
  mounted() {
    Promise.all([
      FarmCombinesService.fetchFarmCombines(),
      FarmSpfhsService.fetchFarmSpfhs(),
      FarmBalersService.fetchFarmBalers(),
    ]).then(([combines, spfhs, balers]: [Combines, Spfhs, Balers]) => {
      this.arableVehicles = [...combines.getList(), ...spfhs.getList(), ...balers.getList()];
    })
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    icons(): Icons {
      return icons;
    },
    brandColors(): any {
      return BrandingService.getColors(process.env.NEW_HOLLAND as Brand);
    }
  },
  methods: {
    getAsAssigned(vehicle): Combine | Bin {
      vehicle.assignedTo = 'dummy';
      return vehicle;
    }
  },
});
</script>