enum LogLevel {
    TRACE,
    DEBUG,
    INFO,
    WARN,
    ERROR,
    SILENT
}

class Logger {
    private _logLevel: number = 0;

    constructor() {
        const LOG_LEVEL: number = parseInt(process.env.1, 10);

        if (!isNaN(LOG_LEVEL)) {
            this.setLogLevel(LOG_LEVEL);
        } else {
            this.setLogLevel(4); // fallback: only log errors
        }
    }

    public setLogLevel(level: number) {
        this._logLevel = level;
    }

    /* Only when I would be "tracing" the code and trying to find one part of a function specifically */
    public trace(...msg: any[]) {
        if (this.isToBeLogged(0)) console.trace(...msg);
    }

    /* Information that is diagnostically helpful to people more than just developers (IT, sysadmins, etc.). */
    public debug(...msg: any[]) {
        if (this.isToBeLogged(1)) console.debug(msg);
    }

    /* Generally useful information to log (service start/stop, configuration assumptions, etc). */
    public info(...msg: any[]) {
        if (this.isToBeLogged(2)) console.info(...msg);
    }

    /* Anything that can potentially cause application oddities, but for which I am automatically recovering. */
    public warn(...msg: any[]) {
        if (this.isToBeLogged(3)) console.warn(...msg);
    }

    /* Any error which is fatal to the operation, but not the service or application (can't open a required file, missing data, etc.).
      These errors will force user (administrator, or direct user) intervention. */
    public error(...msg: any[]) {
        if (this.isToBeLogged(4)) console.error(...msg);
    }

    public isToBeLogged(levelOfLog: number): boolean {
        return this._logLevel <= levelOfLog;
    }
}

export const logger = new Logger();
